// Glidejs
import Glide from "@glidejs/glide";
window.Glide = Glide;
import responsiveVideo from "../components/_responsive-video";

/* ====================== Glide.js ====================== */
new Glide(".glide", {
    type: "carousel",
    startAt: 0,
    perView: 3,
    perTouch: 3,
    gap: 20,
    breakpoints: {
        1400: {
            perView: 2,
        },

        767: {
            perView: 1,
        },
    },
}).mount();

responsiveVideo();

/* ============= TRIGGER POPUP ON PAGE LOAD ============= */
// triggerPopup("#exampleModal");
